import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBarBurger from "./NavbarBurger";
import NavItem from "./Navitem";

const NavBar: FunctionComponent = ({}) => {
  const [showDropDownYdelser, setShowDropDownYdelser] = useState(false);
  const [showDropDownArtikler, setShowDropDownArtikler] = useState(false);

  const onMouseDownYdelser = (): void => {
    setShowDropDownYdelser(false);
  };

  const onMouseEnterydelser = (): void => {
    setShowDropDownYdelser(true);
  };
  const onMouseDownArtikler = (): void => {
    setShowDropDownArtikler(false);
  };

  const onMouseEnterArtikler = (): void => {
    setShowDropDownArtikler(true);
  };
  return (
    <div className="relative font-inter">
      <NavBarBurger></NavBarBurger>
      <div>
        <img
          className="absolute top-0 max- max-md:h-28"
          src="../vector11.svg"
          alt="Navbar line"
          title="Navbar line"
          loading="lazy"
        />
      </div>

      <div className="container pt-[30px] flex flex-wrap items-center justify-center mx-auto max-lg:hidden">
        <nav>
          <div className="relative gap-10 flex flex-wrap items-center justify-center mx-auto text-2xs font-semibold">
            <Link
              className="max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-indigo "
              to="/"
            >
              Hjem
            </Link>

            <Link
              className="max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-indigo "
              title="Min baggrund"
              to="/min-bagrund"
            >
              Min baggrund
            </Link>

            <div
              className="max-w-22"
              onMouseEnter={onMouseEnterydelser}
              onMouseLeave={onMouseDownYdelser}
              onClick={onMouseDownYdelser}
              onFocus={onMouseEnterydelser}
            >
              <Link
                className="max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-indigo  "
                title="Ydelser"
                to="/ydelser"
              >
                Ydelser
              </Link>
              {showDropDownYdelser ? (
                <div className="z-10 absolute divide-y divide-gray-100 rounded-lg shadow-xl w-48  bg-indigo">
                  <ul className="overflow-visible list-none pl-2 gap-2 space-y-2">
                    <NavItem to="/ydelser" title="Alle Ydelser">
                      Alle Ydelser
                    </NavItem>
                    <NavItem to="/ydelser/skyggearbejde" title="Skyggearbejde">
                      Skyggearbejde
                    </NavItem>
                    <NavItem to="/ydelser/healing" title="Healing">
                      Healing
                    </NavItem>
                    <NavItem
                      to="/ydelser/terapi-for-unge"
                      title="Terapi for unge"
                    >
                      Terapi for unge
                    </NavItem>
                    <NavItem to="/ydelser/hsp" title="HSP">
                      HSP
                    </NavItem>
                    <NavItem
                      to="/ydelser/indre-barn-terapi"
                      title="Indre barn terapi"
                    >
                      Indre barn terapi
                    </NavItem>
                    <NavItem
                      to="/ydelser/individuel-terapi"
                      title="Individuel terapi"
                    >
                      Individuel terapi
                    </NavItem>
                    <NavItem to="/ydelser/kriseterapi" title="Kriseterapi">
                      Kriseterapi
                    </NavItem>
                    <NavItem to="/ydelser/stressterapi" title="Stressterapi">
                      Stressterapi
                    </NavItem>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>

            <Link
              className="max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-indigo  "
              to="/priser"
            >
              Priser
            </Link>

            <div
              className="max-w-22"
              onMouseEnter={onMouseEnterArtikler}
              onMouseLeave={onMouseDownArtikler}
              onClick={onMouseDownArtikler}
              onFocus={onMouseEnterArtikler}
            >
              <Link
                className="max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-indigo"
                to="/artikler"
              >
                Artikler
              </Link>
              {showDropDownArtikler ? (
                <div className="z-10 absolute divide-y divide-gray-100 rounded-lg shadow-xl w-48  bg-indigo">
                  <ul className="overflow-visible list-none pl-2 gap-2 space-y-2">
                    <NavItem to="/artikler" title="Alle artikler">
                      Alle artikler
                    </NavItem>
                    <NavItem to="/artikler/hjerterum" title="Artikel Hjerterum">
                      Hjerterum
                    </NavItem>
                    <NavItem to="/artikler/kognitiv-terapi" title="Kognitiv">
                      Kognitiv terapi
                    </NavItem>
                    <NavItem to="/artikler/meditation" title="Meditation">
                      Meditation
                    </NavItem>
                    <NavItem to="/artikler/mindfullness" title="Mindfullness">
                      Mindfullness
                    </NavItem>
                    <NavItem to="/artikler/skyggesider" title="Skyggesider">
                      Skyggesider
                    </NavItem>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Link
              className="max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-indigo"
              to="/hjerterum"
              title="Hjerterum"
            >
              Hjerterum
            </Link>

            <Link
              className="max-w-sm lg:ml-16 relative px-4 py-2 no-underline text-[#6f336a] bg-white border border-gray-200 hover:bg-gray-300 rounded-full"
              to="/kontakt"
            >
              Kontakt
            </Link>
          </div>
        </nav>
      </div>
      <img
        className="absolute top-[0px] left-[1px] w-[9.5%] min-w-[75px]"
        alt="Zenna Logo"
        title="Zenna Logo"
        src="../zen_logo1.png"
        loading="lazy"
      />
    </div>
  );
};

export default NavBar;
