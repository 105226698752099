import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConsentOptions, ConsentProvider, ConsentBanner, useConsent } from 'react-hook-consent';
import "react-hook-consent/dist/styles/style.css";
import "./global.css";
import ReactDOM from "react-dom";

const container = document.getElementById("root");
const options: ConsentOptions = {
  services: [
    {
        id: 'analytics',
        name: 'Google',
        description: "Giver google lov til at indsamle data om besøg.",
        cookies: [{ pattern: 'cookie-name' }],
    },
    {
        id: 'recaptcha',
        name: 'Recaptcha',
        description: "Giver dig lov til at sende en besked via kontaktformen under kontakt, for at sikre at den ikke bliver misbrugt",
        scripts: [{ id: 'external-script', src: 'https://www.google.com/recaptcha/api.js' }],
        cookies: [{ pattern: 'cookie-name' }],
        mandatory: true,
    },
],
theme: 'light',
}

const appJsx = (
  <BrowserRouter>
    <ConsentProvider options={options}>
      <App />
      <ConsentBanner
        settings={{ 
          // your settings here...
        }}
        decline={{ label: 'Afslå' }}
        approve={{ label: 'Accepter' }}
      > 
        <>
          Kan vi bruge cookies og eksterne tjenester i henhold til vores <a href="privatlivspolitik">privatlivspolitik</a> for at forbedre brugeroplevelsen?
        </>
      </ConsentBanner>
    </ConsentProvider>
  </BrowserRouter>
);

if (container!.hasChildNodes()) {
  ReactDOM.hydrate(appJsx, container);
} else {
  ReactDOM.render(appJsx, container);
}

reportWebVitals();