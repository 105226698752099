import { FunctionComponent, useState } from "react";
import {
  Link
} from "react-router-dom";
import { stack as Menu } from 'react-burger-menu'

const NavBarBurger: FunctionComponent = ({ }) => {
  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      right: '36px',
      top: '12px'
    },
    bmBurgerBars: {
      background: 'grey'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#7d406c',
      padding: '2em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }


  }

  const [showDropDownYdelser, setShowDropDownYdelser] = useState(false);
  const [showDropDownArtikler, setShowDropDownArtikler] = useState(false);
  const [isOpen, setisOpen] = useState({ menuOpen: false });

  const handleState = (state: any): void => {
    setisOpen(state)
  }

  const closeMenu = (): void => {
    setisOpen({ menuOpen: false })
  }

  const changeStateArtikler = (): void => {
    showDropDownArtikler ? setShowDropDownArtikler(false) : setShowDropDownArtikler(true);
  }

  const changeStateYdelser = (): void => {
    showDropDownYdelser ? setShowDropDownYdelser(false) : setShowDropDownYdelser(true);
  }

  return (
    <div className="lg:hidden">
      <Menu right styles={styles} isOpen={isOpen.menuOpen} onStateChange={(state) => handleState(state)} >
        <nav >
          <div className="flex flex-col">

            <Link onClick={closeMenu} className="menu-item max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/">Hjem</Link>

            <Link onClick={closeMenu} className="menu-item max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/min-bagrund"> Min baggrund</Link>

            <div className="max-w-22" >
              <span onClick={changeStateYdelser} className="cursor-pointer max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" >Ydelser</span>
              {
                showDropDownYdelser ?
                  <div className="z-10 relative divide-y divide-gray-100 rounded-lg shadow-xl w-48  bg-indigo">
                    <ul className="overflow-visible list-none pl-2 gap-2 space-y-2">
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser">Alle ydelser</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" title="Skyggearbejde" to="/ydelser/skyggearbejde">Skyggearbejde</Link>
                      </li>
                      <li className="">
                        <Link  onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/terapi-for-unge">Terapi for unge</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/healing">Healing</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/hsp">HSP</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/indre-barn-terapi">Indre barn terapi</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/individuel-terapi">Individuel terapi</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/kriseterapi">Kriseterapi</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/ydelser/stressterapi">Stressterapi</Link>
                      </li>
                    </ul>
                  </div>
                  : <></>
              }
            </div>


            <Link onClick={closeMenu} className="menu-item max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/priser">Priser</Link>


            <div className="max-w-22" >
              <span onClick={changeStateArtikler} className="cursor-pointer max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" >Artikler</span>
              {
                showDropDownArtikler ?
                  <div className="z-10 relative divide-y divide-gray-100 rounded-lg shadow-xl w-48  bg-indigo">
                    <ul className="overflow-visible list-none pl-2 gap-2 space-y-2">
                    <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/artikler">Alle artikler</Link>
                      </li>
                    <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/artikler/hjerterum">Hjerterum</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/artikler/kognitiv-terapi">Kognitiv terapi</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/artikler/meditation">Meditation</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/artikler/mindfullness">Mindfullness</Link>
                      </li>
                      <li className="">
                        <Link onClick={closeMenu} className="max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2" to="/artikler/skyggesider">Skyggesider</Link>
                      </li>
                    </ul>
                  </div>
                  : <></>
              }
            </div>
            <div className="mb-3 mt-1">
            <Link onClick={closeMenu} className="menu-item max-w-sm p-1 no-underline text-white rounded-lg border-0 hover:border-purple-200 hover:border-solid hover:border-b-2"  to="/hjerterum">Hjerterum</Link>
            </div>
            <Link onClick={closeMenu} className="menu-item max-w-sm lg:ml-28 relative px-4 py-2 no-underline bg-white border border-gray-200 hover:bg-gray-100  dark:border-gray-700 dark:hover:bg-gray-700" to="/kontakt">Kontakt</Link>
          </div>
        </nav>
      </Menu>
    </div>
  );
};

export default NavBarBurger;
